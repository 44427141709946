html {
    scroll-behavior: smooth;
}

.form {
    box-sizing: border-box;
    min-height: 100%;
    margin: 0 auto;
    color: ghostwhite;
    font-size: 4em;
    width: fit-content;
    padding: 0 5em;

}

.form .next {
    margin: 0.5em 0;
    text-align: center;
    font-size: 2em;
}

.form .next svg {
    color: ghostwhite;
}

.form .caption {
    text-align: center;
}

.form .caption .just {
    text-align: center;
    font-size: 0.8em;
}

.form .content .just {
    text-align: left;
    font-size: 0.8em;
}

.form .hint {
    font-weight: 200;
}

.form .caption p, 
.form .content p  {
    margin: 0;
    padding: 0.5em 0;
}

.form .content .boxlist {
    margin: 0.5em 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.form .content .question p {
    text-align: justify;
    font-family: 'DIN Alternate';
}

/*
.form .content .checkbox.item {
    margin: 0;
    padding: 0.5em 0;
}
*/
.form .caption.welcome p {
    margin-top: 1em;
}

.form .banner img {
    width:  100%;
    height: auto;
    object-fit: scale-down;
    margin-top: 1em;
    content: url(./banner_desktop.png);
}

@media only screen and (max-width: 1400px) {
    .form {
        padding: 0 4em;
        font-size: 4em;
    }
    .form .caption, .form .content, .form .control, .form .error {
        font-size: 0.8em;
    }
    .form .banner img {
        content: url(./banner_tablet.png);
    }
}

@media only screen and (max-width: 1000px) {
    .form {
        padding: 0 3em;
        font-size: 3em;
    }
    .form .caption, .form .content, .form .control, .form .error {
        font-size: 0.8em;
    }
    .form .banner img {
        content: url(./banner_tablet.png);
    }
}

@media only screen and (max-width: 800px) {
    .form {
        padding: 0 15%;
        font-size: 2em;
    }
    .form .caption, .form .content, .form .control, .form .error {
        font-size: 0.8em;
    }
    .form .banner img {
        content: url(./banner_mobile.png);
    }
}

@media only screen and (max-width: 500px) {
    .form .control button {
        width: 100%;
    }
    .form .banner img {
        content: url(./banner_mobile.png);
    }
}

.form .content .checkbox.item {
    display: flex;
    flex-direction: row;
}

.form .content .checkbox.item svg {
    padding: 0.075em;
}

.form .content .checkbox[data-disabled=true] {
    color: gray;
}

.form .content .checkbox[data-disabled=true]   svg:hover,
.form .content .checkbox[data-disabled=true] label:hover {
    cursor: default;
}

.form .content .checkbox[data-checked=true] svg {
    color: #61dafb; 
}
/*
.form .content {
    background: rgba(40, 44, 52, 0.5);
}*/

.form .content .statement-start {
    font-family: 'DIN Alternate';
    text-align: justify;
    font-size: 0.8em;
/*    font-family: Menlo; 
    font-weight: 800; */
}

.form .content .checkbox {
    /*margin-top: 1em; */
}

.form .content .checkbox   svg:hover,
.form .content .checkbox label:hover {
    cursor: pointer;
    /* color: rgba(97, 218, 251, 0.1); */
}

.form .content .checkbox svg {
    font-size: 0.8em;
}

.form .content .paragraph {
 /*   margin: 1em 0; */
}

.form .content .mailtext {
    flex-direction: row;
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
    font-family: 'DIN Alternate';
    text-align: justify;
}

.form .content .mailaddr {
    display: flex;
    justify-content: center;
}

.form .content .mailaddr .item {
    width: 100%;
    display: flex;
    justify-content: center;
}

.form .content .mailaddr .item input {
    font-size: 0.8em;
    width: 100%;
    max-width: 15em;
    padding: 0.125em 0.25em;
    box-sizing: border-box;
}

.form .content .freetext {
    display: flex;
    flex-direction: column;
    font-size: 0.8em;
}

.form .content .freetext label {
    margin-bottom: 0.5em;
}

.form .content .freetext textarea {
    font-size: inherit;
    padding: 0 0.25em;
    width: 100%;
    box-sizing: border-box;
    font-family: 'DIN Alternate';
}

.form .content .checkbox label {
    margin-left: 0.5em;
    font-family: 'DIN Alternate';
}

.form .error {
    text-align: center;
    color: rgb(238, 212, 62);
}

.form .error span[data-hidden=true] {
    visibility: hidden;
}

/*
.success {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    min-height: 10em;
    color: ghostwhite;
    font-size: 3em;
    text-align: center;
}
*/

.form .control {
    /* font-size: 2.5em;  */
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 4em; 
}

.form .control button:hover {
    cursor: pointer;
}

.form .control button {
    max-width: 15em;
    font-size: 0.8em;
    font-family: 'DIN Alternate';
    padding: 0.25em 1.0em;
    border-radius: 0.25em; 
    background-color: #61dafb;
    border: 0;
    color: #282c34;
}
