


.success {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    min-height: 10em;
    color: ghostwhite;
    font-size: 3em;
    text-align: center;
}